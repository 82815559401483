







































































































































































































































































































































































































































































































































































































































// 修改头部背景
::v-deep .el-table {
    th {
        padding: 0px ;
        background-color:#f7f8f9;
    }
    thead{
        height: 15px !important;
        color:rgb(71, 70, 70);
        font-size:12px;
    }
    td {
        padding: 3px ;
        font-size:11px;
    }   
} 
.obj{
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 9;
    font-size: 12px;
}
.line-img{
    margin-right:4px;
    width:120px;
    height:90px;
    text-align:center;
    vertical-align:middle;
    display:inline-block;
}
.pub_dialog {
    display: flex;
    justify-content: center;
    align-items: Center;
    overflow: hidden;
    .el-dialog {
        margin: 0 auto !important;
        height: 90%;
        overflow: hidden;
        .el-dialog__body {
            position: absolute;
            left: 0;
            top: 54px;
            bottom: 0;
            right: 0;
            padding: 0;
            z-index: 1;
            overflow: hidden;
            overflow-y: auto;
        }
    }
}
.menuitem {
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    padding-right:3px;
    height:34px;
    line-height:34px;
    display:flex;
}
.home-content-layout{
    background-color: #fff;
    width: 100%;
    height:96%;
    max-height: 96%;
    max-height:-webkit-calc(100% - 31px);
    max-height:-moz-calc(100% - 31px);
    max-height: calc(100% - 31px);  
}
.footer {
    width: 100%;
    position: absolute;
    bottom: 0 ;
    background:#f7F8F9;
    line-height:35px  !important;
    height:35px !important;
    vertical-align: center;
    text-align: center;
    color:#409EFF;
    font-weight: 600;
    font-size:12px;
} 
.item-width  {
    width: 32%;
    color:#409EFF;
}
